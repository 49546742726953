<template>
	<el-dialog destroy-on-close :close-on-click-modal="false" @close="close" ref="dialog" title="导入设备" :visible.sync="show" width="400px">
		<el-form ref="form" label-width="90px" label-position="left">
			<el-upload
				action="http://139.155.34.55:9101/GasSafetySystem/device/importDevice"
				:headers="{
					Authorization: this.token
				}"
				name="file"
				ref="upload"
				multiple
				:limit="1"
				:on-exceed="handleExceed"
				:before-remove="beforeRemove"
				:auto-upload="false"
				:file-list="fileList"
				:on-error="onError"
				:on-success="onSuccess"
				style="height: 100px;">
				<el-button size="small" type="primary">文件选择</el-button>
			</el-upload>
			<el-form-item class="pd">
				<el-button type="primary" @click="onSubmit" style="margin-right: 20px;">导入</el-button>
				<el-button @click=" show = false ">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { TextToCode } from 'element-china-area-data';
	export default {
		data() {
			return {
				show : false,
				typeList:[],
				fileList:[]
			}
		},
		methods: {
			close(){
				this.show = false
			},
			open(){
				this.show = true;
			},
			async onSubmit(){
				this.$refs.upload.submit();
			},
			onError(err, file, fileList){
				this.show = false;
				this.$message({
					type: 'error',
					message: "导入设备文件失败，请重试"
				});
			},
			onSuccess(response, file, fileList)	{
				if( response.code == 200 ){
					this.show = false;
					this.$emit( 'success' , 'ok' );
					this.$message({
						type: 'success',
						message: "导入设备文件成功"
					});
				}
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			}
		},
	}
</script>
<style lang="scss">
	.box {
		height: 25px;
	}

	.el-dialog__header {
		background: linear-gradient(135deg, #6b6be4, #15d8f1);
	}

	.el-dialog__title {
		color: #fff
	}

	.el-dialog__header {
		.el-dialog__headerbtn {
			.el-dialog__close {
				color: #fff;
			}
		}
	}
</style>