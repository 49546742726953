<template>
	<el-dialog destroy-on-close :close-on-click-modal="false" @close="close" ref="dialog" :title=" type == 0 ? '新增' : '编辑' " :visible.sync="show" width="400px">
		<el-form ref="form" label-width="90px" label-position="left" :model="form">
			<el-form-item label="设备编号" prop="deviceNo">
				<div slot="label">设备编号<span style="color: red;"> *</span></div>
				<el-input v-model="form.deviceNo" placeholder="请输入设备编号"></el-input>
			</el-form-item>
			<el-form-item prop="deviceTypeCode" label="请选择设备类型">
				<div slot="label">设备类型<span style="color: red;"> *</span></div>
				<el-select v-model="form.deviceTypeCode" placeholder="请选择设备类型">
				    <el-option
						v-for="( item , i ) in typeList"
						:key="i"
						v-if="i<6"
						:label="item.typeName"
						:value="item.typeCode"
					>
				    </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="设备品牌" prop="brand">
				<el-input v-model="form.deviceBrand" placeholder="请输入设备品牌"></el-input>
			</el-form-item>
			<el-form-item label="设备ID" prop="model">
				<div slot="label">设备ID<span style="color: red;"> *</span></div>
				<el-input v-model="form.deviceId" placeholder="请输入设备ID"></el-input>
			</el-form-item>
			<el-form-item class="pd">
				<el-button type="primary" @click="onSubmit" style="margin-right: 20px;">{{ type == 0 ? '新增' : '编辑' }}</el-button>
				<el-button @click=" show = false ">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { TextToCode } from 'element-china-area-data';
	export default {
		data() {
			return {
				show : false,
				type : 0,
				form : {
					deviceNo : "",
					deviceTypeCode : "",
					deviceBrand : "",
					deviceId : "",
				},
				typeList:[],
				upData:{}
			}
		},
		mounted(){
			this.init_getTypeList();
		},
		methods: {
			init_getTypeList(){
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					}
				});
			},
			close( done ){
				this.$refs.form.resetFields();
				this.reset_form_data() 
			},
			open( data ){
				if( data != '' ){
					this.form = Object.assign( {} , data );
					this.upData = data;
				}
				this.show = true;
			},
			onSubmit( e ){
				if ( this.form.deviceNo == "" ) {
					this.$message({
						type: 'error',
						message: "请选择设备编号"
					});
					return;
				}
				if ( this.form.deviceTypeCode == "" ) {
					this.$message({
						type: 'error',
						message: "请选择设备类型"
					});
					return;
				}
				if ( this.form.deviceId == "" ) {
					this.$message({
						type: 'error',
						message: "请选择设备ID"
					});
					return;
				}
				let form_data = {};
				form_data.deviceBrand = this.form.deviceBrand
				form_data.deviceId = this.form.deviceId
				form_data.deviceNo = this.form.deviceNo
				form_data.deviceTypeCode = this.form.deviceTypeCode
				if( this.type == 1 ){
					form_data.id = this.upData.id;
				}
				// type == 0是新增 1是编辑
				this.$api[ this.type == 0 ? 'device_add' : 'device_update' ]( form_data ).then( res => {
					if( res.code == 200 ){
						this.$message({
							type: 'success',
							message: res.message
						});
						this.$emit( 'success' , 'ok' );
						this.show = false;
					}
				});
			},
			reset_form_data() {
				this.form = {
					deviceNo : "",
					deviceTypeCode : "",
					deviceBrand : "",
					deviceId : "",
				}
			}
		}
	}
</script>

<style lang="scss">
	.box {
		height: 25px;
	}

	.el-dialog__header {
		background: linear-gradient(135deg, #6b6be4, #15d8f1);
	}

	.el-dialog__title {
		color: #fff
	}

	.el-dialog__header {
		.el-dialog__headerbtn {
			.el-dialog__close {
				color: #fff;
			}
		}
	}
</style>
